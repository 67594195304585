////////////////////////////////////////////////////////////////////////////
//                                                                        //
// MIXIN SASS                                                              //
// LAST UPDATE: 15.08.2017                                                //
//                                                                        //
////////////////////////////////////////////////////////////////////////////

@mixin breakpoint($class) {
  @if $class == xs {
    @media (max-width: $screen-xs-max) { @content; }
  }
  
  @else if $class == smup {
    @media (min-width: $screen-sm-min) { @content; }
  }

  @else if $class == sm {
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { @content; }
  }
 
  @else if $class == mdup {
    @media (min-width: $screen-md-min) { @content; }
  }

  @else if $class == md {
    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) { @content; }
  }
 
  @else if $class == lg {
    @media (min-width: $screen-lg-min) { @content; }
  }

  @else if $class == xl {
    @media (min-width: $screen-xl-min) { @content; }
  }
 
  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg, xl";
  }
}

@mixin positionXY($v, $y, $h, $x) {
  position: absolute;

  @if $y == center and $x == center {
    top: 50%;
    bottom: initial;
    left: 50%;
    right: initial;
    transform: translate(-50%, -50%);
  } @else {
    @if $y == center {
      top: 50%;
      bottom: initial;
      transform: translateY(-50%);
    } @else {
      @if $v == t {
        top: $y;
      } @else if $v == b {
        bottom: $y;
      }
    }

    @if $x == center {
      left: 50%;
      right: initial;
      transform: translateX(-50%);
    } @else {
      
      @if $h == l {
        left: $x;
      } @else if $h == r {
        right: $x;
      }
    }
  }
}

@mixin boxSize($width, $height, $radius:0px) {
  width: $width;
  height: $height;
  border-radius: $radius;
}

@mixin opacityHover($opacity:0.6) {
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s;

  &:hover {
    opacity: $opacity;
  }
}

@mixin aspectRatio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin centerXY {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}