body {
  background: linear-gradient(
    90deg,
    #eef2f5 270px,
    #d9e2e9 300px,
    #ffffff 300px
  );
  font-size: 12px;
  line-height: 1.7;
  color: #2a2d2f;
}

h1,
h2,
h3,
h4,
h5 {
  color: $tc-dark;
  margin-top: 0;
  font-weight: 700;
}

h1 {
  font-size: $h1-font-size;
}
h2 {
  font-size: $h2-font-size;
}
h3 {
  font-size: $h3-font-size;
}

a {
  &:hover {
    text-decoration: none;
  }
}

.fz14 {
  font-size: 14px;
}

.fz22 {
  font-size: 22px;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.tc-dark {
  color: $tc-dark;
}

.wbbw {
  word-break: break-word;
}

.wspw {
  white-space: pre-wrap;
}

.main-wrapper {
  .main-container {
    padding-left: 300px;
    height: 100vh;
    // overflow-y: scroll;
  }

  .main-row {
    // display: flex;
    min-height: 100vh;
    max-width: 1200px;
    margin: 0 auto;
    // background-color: #fc0;
  }
}

.page-wrapper {
  // min-height: 100vh;
  // height: 100%;
  background-color: #fff;
  padding-bottom: 75px;
}

.full-border {
  border: 1px solid $element-border-color;
  border-radius: 3px;
}

.pagination {
  ul {
    list-style: none;
    padding: 0;

    li {
      display: inline-block;
      width: 42px;
      height: 42px;
      cursor: pointer;
      line-height: 1;
      vertical-align: middle;
      text-align: center;
      margin: 0 5px;
      color: $tc-sky;
      font-weight: 700;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 42px;
      }

      &.prev,
      &.next {
        border-radius: 3px;
        text-indent: -9999px;
        overflow: hidden;
        background: url(./../images/icon-pagi-nav.png) no-repeat;
        background-color: $brand-dark;
        background-position: center center;
      }

      &.next {
        transform: rotate(180deg);
      }

      &.active {
        color: $tc-dark;
      }

      &.dummy {
        opacity: 0;
        cursor: default;
      }
    }
  }
}

.pointer-me {
  cursor: pointer;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.6;
  }
}

.idea-numbered-list {
  padding-left: 10px;

  li {
    margin-bottom: 5px;
  }
}

ul.answers-list {
  list-style: none;

  padding-left: 0px;

  li {
    margin-bottom: 5px;
  }
}

.text-with-links a {
  text-decoration: underline;
  font-weight: 400 !important;
  word-wrap: break-word;
}

.bgc-light {
  background-color: #fcfcfd;
}

.tc-dark {
  color: $tc-dark;
}

.tc-grey {
  color: $tc-grey;
}

.tc-secondary {
  color: $brand-secondary;
}
