.sidebar {
  // min-height: 100vh;
  // height: 600px;
  // background-color: #eef2f5;

  // background: #eef2f5; /* Old browsers */
  // background: -moz-linear-gradient(left, #eef2f5 0%, #eef2f5 90%, #dee6ec 100%); /* FF3.6-15 */
  // background: -webkit-linear-gradient(left, #eef2f5 0%,#eef2f5 90%,#dee6ec 100%); /* Chrome10-25,Safari5.1-6 */
  // background: linear-gradient(to right, #eef2f5 0%,#eef2f5 90%,#dee6ec 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eef2f5', endColorstr='#dee6ec',GradientType=1 ); /* IE6-9 */

  .heading {
    font-size: 16px;
    color: $tc-sky;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .text-search {
    border-bottom: 1px solid $element-border-color;
  }

  .separator {
    height: 1px;
    border-bottom: 1px solid $element-border-color;
    margin: 0 -30px;
  }

  width: 300px;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.sidebar-search {
  // height: 150vh;
  padding-bottom: 50px;
  padding: 0;

  .search-form-wrapper {
    height: calc(100vh - 250px);
    overflow-x: hidden;
    overflow-y: scroll;

    padding-left: 30px;
    padding-right: 15px;
    padding-bottom: 20rem;

    /* Custom scrollbar styles */
    &::-webkit-scrollbar {
      width: 5px; /* Width of the scrollbar */
    }

    &::-webkit-scrollbar-thumb {
      background: #909598; /* Thumb color */
      min-height: 15rem;
    }

    &::-webkit-scrollbar-track {
      background: transparent; /* Track background */
    }
  }
}

.idea-logo {
  height: 225px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // border-bottom: 1px solid $element-border-color;
  // margin: 0 -30px;

  .logo {
    display: block;
    width: 100px;
    height: 90px;
    margin-bottom: 15px;
    background: url(./../images/idea-logo-30years.svg) no-repeat;
    background-size: contain;
  }

  .cnet {
    width: 150px;
    height: 32px;
    margin-bottom: 12px;
    background: url(./../images/cnet-logo.png) no-repeat;
    background-size: contain;
  }

  .peace-rep {
    width: 150px;
    height: 60px;
    background: url(./../images/peacerep-logo.png) no-repeat;
    background-size: contain;
  }
}

.disableSidebar {
  pointer-events: none;
  opacity: 0.3;
}

.filter-tabs {
  transform: translateY(1px);

  .filter-tab {
    padding: 15px 25px;
    display: inline-block;
    border: 1px solid #d5d9dc;
    color: #a9bdc8;
    font-weight: 500;
    background-color: #ebf0f3;
    margin-right: 10px;
    transition: color 0.3s;
    cursor: pointer;

    &.active {
      color: initial;
      border-bottom-color: #ebf0f3;
    }

    &:hover {
      color: initial;
    }
  }
}

.orange-text-div {
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
  padding-bottom: 20px;

  .orange-text {
    // text-align: center;
    color: #e9770f;
    font-size: 13px;
    font-weight: 600;
  }
}
