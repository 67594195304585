@include breakpoint(xs) {
  body,
  body.admin {
    background: #fff;
  }

  .main-wrapper,
  #reactAdmin {
    display: none;
  }

  .mobile-warning {
    padding-bottom: 50px;

    .top-bar {
      background-color: $page-bg;
      // height: 150px;
      margin-bottom: 50px;
      padding: 20px 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .logo {
        width: 25%;
        max-width: 100px;
        // background: url(./../images/idea-logo.png) center center no-repeat;
        // background-size: contain;
        margin: 0 20px;
        flex: 0 0 25%;
      }

      .cnet-logo {
        width: 50%;
        max-width: 190px;
        // background: url(./../images/cnet-logo.png) center center no-repeat;
        // background-size: contain;
        margin: 0 20px 0 0;
        flex: 0 0 50%;
      }
    }

    img {
      width: 90%;
      margin: 0 auto 30px;
    }
  }
}

@include breakpoint(smup) {
  .mobile-warning {
    display: none;
  }
}
