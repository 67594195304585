.page-wrapper-search {
  min-height: 130vh;
}

.search-tags {
  .tag {
    display: inline-block;
    height: 32px;
    background-color: $brand-lite;
    padding: 8px 10px;
    font-size: 11px;
    font-weight: 600;
    color: #fff;
    border-radius: 3px;
    margin: 0 5px 5px 0;
    cursor: pointer;
    min-height: 32px;
    height: auto;

    &.danger {
      border: 1px solid $tc-deepblush;
      background-color: white;
      color: $tc-deepblush;

      .remove {
        background: url(./../images/icon-cross-danger.png) no-repeat;
        background-size: contain;
      }
    }

    .remove {
      width: 9px;
      height: 9px;
      display: inline-block;
      background: url(./../images/icon-cross.png) no-repeat;
      background-size: contain;
      margin-left: 5px;
    }
  }
}

.pin,
.pinned {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  height: 20px;
  margin-left: 15px;
  font-size: 13px;
  font-weight: 700;
  cursor: pointer;
  color: white;
  border-radius: 3px;

  transition: opacity 0.3s;

  &:hover {
    opacity: 0.6;
  }

  & > img {
    margin-right: 5px;
  }
}

.pinned {
  background-color: $brand-dark;

  img {
    width: 6px;
    height: 13px;
  }
}

.pin {
  background-color: $tc-gray-chateau;

  img {
    width: 12px;
    height: 13px;
  }
}

.result-card {
  border: 1px solid $element-border-color;
  border-radius: 5px;

  .title-pin-wrapper {
    display: flex;

    & > .result-pin,
    & > .result-pinned {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      height: 28px;
      margin-left: 15px;
      font-size: 13px;
      font-weight: 700;
      cursor: pointer;
      color: white;
      border-radius: 3px;

      transition: opacity 0.3s;
      &:hover {
        opacity: 0.6;
      }

      & > img {
        margin-right: 5px;
      }

      @at-root .result-pinned {
        background-color: $brand-dark;
      }
      @at-root .result-pin {
        background-color: $tc-gray-chateau;
      }
    }

    .result-pinned {
      background-color: $brand-dark;

      img {
        width: 9px;
        height: 18px;
      }
    }

    .result-pin {
      background-color: $tc-gray-chateau;

      img {
        width: 16px;
        height: 18px;
      }
    }
  }

  .result-header-link {
    &:hover {
      .card-header {
        // background-color: $brand-lite;
        .title {
          // color: white;
        }
      }
    }
  }
  .card-header {
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    padding: 15px 40px;
    border-bottom: 1px solid $element-border-color;

    .title {
      font-size: 18px;
      color: $tc-dark;

      a {
        color: $tc-dark;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .card-body {
    table {
      width: 70%;
      th {
        padding-right: 40px;
      }

      th,
      td {
        vertical-align: top;
      }
    }

    .summary {
      padding: 30px 40px;
      border-bottom: 1px solid $element-border-color;

      table {
        td {
          width: 60%;
        }
      }

      .hlp-flex {
        align-items: flex-end;

        > .remove-search {
          margin-top: 20px;
        }
      }
    }

    .article {
      padding: 30px 40px;

      .heading {
        color: $tc-grey;
        font-size: 16px;
        font-weight: 700;
      }
    }

    .title {
      display: flex;
    }
  }

  .divider {
    height: 2px;
    border-bottom: 1px solid $element-border-color;
    margin: 30px 0px 30px;
  }

  .text-result {
    b {
      color: $brand-accent;
    }
  }
}

.no-results {
  display: flex;
  height: 200px;
  align-items: center;
  justify-content: center;
}
