.about {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding: 0;
  max-width: 710px;
  margin: 0 auto;

  h4 {
    font-size: 15px;
    font-weight: 600;
  }

  h5 {
    font-size: 13px;
    font-weight: 600;
  }

  p,
  a {
    font-size: 13px;
  }

  .link {
    text-decoration: underline;
    cursor: pointer;
    color: $tc-dark;

    img {
      margin-right: 10px;
    }

    .arrow-link {
      width: 10px;
      height: 10px;
    }

    p {
      margin-bottom: 0px;
    }

    &:hover {
      opacity: 0.6;
      transition: opacity 0.3s;
    }
  }

  .how-to-explore-step-row {
    display: flex;

    .step-title {
      flex: 0.4;
    }

    .step-content {
      flex: 0.6;
      display: flex;
      flex-direction: column;
    }
  }

  .vertical-center {
    display: flex;
    align-items: center;
  }
}

.video-about {
  width: 100%;
  aspect-ratio: 16 / 9;
}

.video-container {
  width: 100%;
  aspect-ratio: 16 / 9;
  padding-bottom: 2rem;
}
