.btn {
  height: 42px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  /* text-transform: uppercase; */

  &.default {
    width: 140px;
  }

  &.primary {
    background-color: $brand-dark;
    color: #fff;
    border-color: $brand-dark;
  }

  &.secondary {
    background-color: $brand-lite;
    color: #fff;
    border-color: $brand-lite;
  }

  &.invert {
    background-color: #fff;
    border-color: $brand-dark;
    color: $tc-dark;
  }

  &.accent-invert {
    background-color: #fff;
    color: $brand-accent;
    border-color: $brand-accent;
  }

  &.clear {
    background-color: transparent;
    border-color: $element-border-color;
    color: $tc-dark;
  }

  &.no-border {
    border-color: transparent;
  }

  &.accent-border {
    border-color: $brand-accent;
  }

  &.fw {
    width: 100%;
  }

  &.plain {
    border: none;
    display: inline-block;
    padding: 0;
  }

  &.iconized {
    padding-left: 30px;

    &.pinboard {
      background: url(./../images/icon-pin-on-stages.png) no-repeat;
      background-size: 8px 16px;
      background-position: top 50% left 15px;
    }

    &.document {
      padding-left: 40px;
      background: url(./../images/icon-document.png) no-repeat;
      background-size: 17px 21px;
      background-position: top 50% left 15px;
    }

    &.download-full-dataset {
      padding-left: 40px;
      background: url(./../images/icon-document.png) no-repeat;
      background-size: 17px 21px;
      background-position: top 50% left 15px;
      text-transform: initial;
      font-size: 13px;
      font-weight: bold;
      border: none;
    }

    &.finder {
      padding-left: 45px;
      color: $tc-accent;
      text-transform: initial;
      background: url(./../images/icon-finder.png) no-repeat;
      background-size: 18px 17px;
      background-position: top 50% left 15px;
    }

    &.full-view {
      padding-left: 35px;
      background: url(./../images/icon-full-view.png) no-repeat;
      background-position: top 50% left 15px;
      background-size: 12px 10px;
      text-transform: initial;
      border: 1px solid $tc-dark;
      margin-top: 15px;
      line-height: 1.5rem;
      color: white;
      background-color: $tc-dark;


      &.view-article {
        height: 20px;
        padding: 0;
        padding-left: 25px;
        background-position: top 50% left 0px;
        color: $tc-medium;
      }
    }

    &.back {
      background: url(./../images/icon-left.png) no-repeat;
      background-size: 6px 10px;
      height: 20px;
      padding: 0;
      padding-left: 15px;
      background-position: top 50% left 0px;
      color: $tc-sky;
    }

    &.export {
      min-width: 188px;
      height: 32px;
      padding-left: 40px;
      background: url(./../images/icon-document-accent.png) no-repeat;
      background-size: 17px 21px;
      background-position: top 50% left 15px;
      color: $tc-accent;
      text-align: left;
    }

    &.remove-search {
      height: 30px;
      padding-left: 40px;
      background: url(./../images/icon-remove-search.png) no-repeat;
      background-size: 20px 20px;
      background-position: top 50% left 15px;
      color: $tc-tamarillo;
      text-align: left;
    }
  }

  &.export-all {
    text-align: left;
  }

  &.center-items {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      margin-right: 10px;
    }
  }

  transition: opacity 0.3s;

  &:hover {
    opacity: 0.6;
  }

  &.remove-search:hover {
    opacity: 1;
    color: $tc-deepblush;
    transition: color 0.3s;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;

  &.two-buttons {
    .btn {
      width: 47.5%;
    }
  }
}

.actions.text-right {
  .btn {
    margin-left: 10px;
    margin-bottom: 10px;

    &:first-child {
      margin-left: 0;
    }
  }

  .export {
    min-width: initial;
  }
}

a.btn {
  padding-top: 12px;
}
