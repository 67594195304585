.filter-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .remove-filter {
    color: $tc-deepblush;
    cursor: pointer;
    font-weight: 700;
    margin-left: 10px;
  }
}

.relative {
  position: relative;
}

.add-filter {
  color: $tc-dark;
  cursor: pointer;
  font-weight: 700;
  margin-bottom: 10px;
}

.drag-handle {
  width: 1rem;
  height: 14px;
  background: url(./../images/icon-drag.svg) center no-repeat;
  background-size: contain;
}
