.hlp-flex {
  display: flex !important;

  &.flex-dr {
    flex-direction: row;
  }

  &.flex-dc {
    flex-direction: column;
  }

  &.flex-aic {
    align-items: center;
  }

  &.flex-aifs {
    align-items: flex-start;
  }

  &.flex-aife {
    align-items: flex-end;
  }

  &.flex-jcc {
    justify-content: center;
  }

  &.flex-jcfs {
    justify-content: flex-start;
  }

  &.flex-jcfe {
    justify-content: flex-end;
  }

  &.flex-jcsa {
    justify-content: space-around;
  }

  &.flex-jcsb {
    justify-content: space-between;
  }

  &.flex-jcse {
    justify-content: space-evenly;
  }

  &.flex-fww {
    flex-wrap: wrap;
  }

  $i: 5;
  @while $i < 101 {
    .flex-pc-#{$i} { flex: 0 0 ($i * 1%); }
    $i: $i + 5;
  }

  .flex-grow-me {
    flex-grow: 1;
  }
}

.hlp-posr {
  position: relative;

  .center-me {
    @include positionXY(t, center, l, center);
  }
}

.hlp-fw {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100% !important;
  min-width: initial !important;
}

.hlp-fh {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  height: 100%;
  min-height: initial !important;
}

// MARGIN HELPERS
//////////////////////////////////////////////////////////////////////

.hlp-no-margin { margin: 0 !important; }

// Margin All hlp-mg-##
$i: 0;
@while $i < 101 {
  .hlp-mg-#{$i} { margin: $i * 1px; }
  $i: $i + 5;
}

// Margin Top hlp-mt-##
$i: 0;
@while $i < 101 {
  .hlp-mt-#{$i} { margin-top: $i * 1px; }
  $i: $i + 5;
}

// Margin Bottom hlp-mb-##
$i: 0;
@while $i < 101 {
  .hlp-mb-#{$i} { margin-bottom: $i * 1px; }
  $i: $i + 5;
}

// Margin Left hlp-ml-##
$i: 0;
@while $i < 101 {
  .hlp-ml-#{$i} { margin-left: $i * 1px; }
  $i: $i + 5;
}

// Margin Right hlp-mr-##
$i: 0;
@while $i < 101 {
  .hlp-mr-#{$i} { margin-right: $i * 1px; }
  $i: $i + 5;
}

// PADDING HELPERS
//////////////////////////////////////////////////////////////////////

.hlp-no-padding { padding: 0; }

// Padding All hlp-pd-##
$i: 0;
@while $i < 101 {
  .hlp-pd-#{$i} { padding: $i * 1px; }
  $i: $i + 5;
}

// Padding Top hlp-pt-##
$i: 0;
@while $i < 101 {
  .hlp-pt-#{$i} { padding-top: $i * 1px; }
  $i: $i + 5;
}

// Padding Bottom hlp-pb-##
$i: 0;
@while $i < 101 {
  .hlp-pb-#{$i} { padding-bottom: $i * 1px; }
  $i: $i + 5;
}

// Padding Left hlp-pl-##
$i: 0;
@while $i < 101 {
  .hlp-pl-#{$i} { padding-left: $i * 1px; }
  $i: $i + 5;
}

// Padding Right hlp-pr-##
$i: 0;
@while $i < 101 {
  .hlp-pr-#{$i} { padding-right: $i * 1px; }
  $i: $i + 5;
}