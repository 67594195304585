.home-advanced-search {
  // background-color: #fcfcfd;

  .toggle {
    font-size: 12px;
    line-height: 1;
    text-transform: uppercase;
    font-weight: 600;
    color: $brand-secondary;
    display: inline-block;
    // background: url(./../images/icon-down.png) no-repeat;
    // background-position: top 50% right 0px;
    // background-color: #fcfcfd;
    padding: 25px 40px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
    border-bottom: 15px solid white;
    position: relative;

    &.active {
      border-bottom: 15px solid #fcfcfd;

      &:after {
        background-image: url(./../images/icon-up.png);
      }
    }

    &:after {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-left: 10px;
      background: url(./../images/icon-down.png) center center no-repeat;

      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
    }
  }

  .gradient-border {
    height: 1px;

    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#d6dadd+0,d6dadd+40,d6dadd+60,d6dadd+100&0+0,1+40,1+60,0+100 */
    background: -moz-linear-gradient(
      left,
      rgba(214, 218, 221, 0) 0%,
      rgba(214, 218, 221, 1) 40%,
      rgba(214, 218, 221, 1) 60%,
      rgba(214, 218, 221, 0) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      left,
      rgba(214, 218, 221, 0) 0%,
      rgba(214, 218, 221, 1) 40%,
      rgba(214, 218, 221, 1) 60%,
      rgba(214, 218, 221, 0) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to right,
      rgba(214, 218, 221, 0) 0%,
      rgba(214, 218, 221, 1) 40%,
      rgba(214, 218, 221, 1) 60%,
      rgba(214, 218, 221, 0) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00d6dadd', endColorstr='#00d6dadd',GradientType=1 ); /* IE6-9 */
  }

  .form-block {
    display: none;

    &.active {
      display: block;
    }
  }
}

.home-copy {
  max-width: 600px;
  text-align: justify;
  margin: 0 auto;
}

.topic-search-block {
  display: flex;

  .topic-search-label {
    flex: 0 0 160px;
    padding-right: 20px;
  }

  .topic-search-fields {
    flex: 1;
  }

  .field-wrapper {
    margin-bottom: 0;
    display: block;
    // max-width: 300px;
    max-width: 100%;

    input,
    label {
      margin-top: 0;
      margin-bottom: 0;
    }

    &.topic {
      input {
        margin-top: 5px;
      }
    }

    input[type='checkbox'] + label {
      max-width: 95%;
    }
  }

  .topics-toggle {
    background: url(./../images/icon-down.png) no-repeat;
    background-position: center center;
    cursor: pointer;

    width: 20px;
    height: 20px;
    position: absolute;
    top: 0px;
    right: 0px;

    &.active {
      background-image: url(./../images/icon-up.png);
    }
  }
}

.sidebar {
  .topic-search-block {
    display: block;
  }
}
