.processes-table {
  width: 100%;

  tr {
    &:nth-child(even) {
      background-color: #f8fafb;
    }
  }

  td {
    padding: 20px;
  }

  .sort-column {
    cursor: pointer;

    &:after {
      content: '';
      display: inline-block;
      width: 14px;
      height: 14px;
      background: url(./../images/icon-down.png) center center no-repeat;
      vertical-align: text-bottom;
      margin-left: 5px;
    }

    &.dsc {
      &:after {
        background-image: url(./../images/icon-up.png);
      }
    }
  }

  .sort-column.desc:after {
    transform: rotate(180deg);
  }

  .title {
    color: $tc-dark;
    font-weight: 700;
    width: 100%;
  }

  .datetime {
    min-width: 200px;
  }

  .action {
    width: 85px;
    span {
      cursor: pointer;
      color: $brand-primary;
    }
  }
}

.helptip {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url(./../images/icon-tooltip.png) no-repeat;
  background-size: contain;
  position: relative;

  &:hover {
    opacity: 0.6;
  }
}
