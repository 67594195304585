// Body
$body-bg: #333;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #3097d1;
$brand-secondary: #63889e;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

// Typography
$icon-font-path: '~bootstrap-sass/assets/fonts/bootstrap/';
$font-family-sans-serif: 'Open Sans', sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #888d90;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;

// Poet Farmer
$brand-dark: #2c495f; //#2d4b62;
$brand-lite: #4e8ba8;
$brand-accent: #d59f6d;
$brand-sky: #a9bdc8;

$page-bg: #eef2f5;

$btn-default-color: $brand-dark;
$tc-dark: $brand-dark;
$tc-medium: #63889e;
$tc-tamarillo: #a91620;
$tc-deepblush: #e57d7d;
$tc-grey: #585b5d;
$tc-accent: $brand-accent;
$tc-placeholder: #a5a7a9;
$tc-sky: $brand-sky;
$tc-gray-chateau: #9b9fa2;
$tc-white: #fff;

$h1-font-size: 32px;
$h2-font-size: 26px;
$h3-font-size: 18px;
$h4-font-size: 16px;

$element-border-color: #d5d9dc;

$grid-gutter-width: 60px;
