.process-detail-header {
  .detail-header {
    .title {
      font-size: 26px;
      font-weight: 700;
      color: $tc-dark;
    }
  }

  table {
    th {
      padding-right: 40px;
    }

    th,
    td {
      vertical-align: top;
    }
  }
}

.detail-card {
  .card-header {
    border: 1px solid $element-border-color;
    border-radius: 5px;
    height: 60px;
    padding: 0 20px;
    margin-bottom: 10px;
    cursor: pointer;

    .stage {
      color: $tc-sky;
      font-weight: 700;
      line-height: 1;
    }

    .title {
      color: $tc-dark;
      font-weight: 700;
      font-size: 14px;
    }

    .toggle {
      display: inline-block;
      width: 16px;
      height: 16px;
      background: url(./../images/icon-up.png) no-repeat;
      background-position: center center;
      margin-left: 10px;
      vertical-align: middle;
      cursor: pointer;

      &.collapsed {
        background-image: url(./../images/icon-down.png);
      }
    }

    .card-tooltip {
      display: flex;
      gap: 1rem;
    }

    .icon-tooltip {
      width: 18px;
      height: 18px;
    }
  }

  .card-body {
    position: relative;
    background: #ffffff;

    .section {
      margin-bottom: 20px;

      .title {
        font-weight: 700;
      }

      .active-switch {
        display: flex;
        align-items: center;

        input[type='checkbox'] {
          margin: 0;
        }

        label.action {
          padding-left: 5px;
          margin: 0;
        }
      }

      a,
      span {
        font-weight: 700;
        color: $tc-dark;
      }

      a.action,
      span.action,
      label.action {
        color: $tc-medium;
        /* text-transform: uppercase; */
        margin-bottom: 5px;
        padding-left: 17px;
        background-size: 12px 12px;
        cursor: pointer;

        &.edit {
          background: url(./../images/icon-pencil.png) center left no-repeat;
        }

        &.undo {
          background: url(./../images/icon-undo.png) center left no-repeat;
        }

        &.delete {
          background: url(./../images/icon-trash.png) center left no-repeat;
        }
      }

      .last-updated {
        font-style: italic;
        margin-bottom: 5px;
      }

      p:not(.text-with-links) {
        display: flex;
      }

      p.block {
        display: block;
      }

      .pin,
      .pinned {
        color: white;
      }
    }

    .sort-draggable {
      width: 16px;
      height: 16px;
      background: url(./../images/icon-hamburger-accent.png) no-repeat;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }
  }

  .divider {
    height: 2px;
    border-bottom: 1px solid $element-border-color;
    margin: 0 20px 20px;
  }

  .property-divider {
    height: 2px;
    border-bottom: 1px solid $element-border-color;
    margin: 20px 0;
  }

  .details-wrapper div.card-body-wrapper {
    &:last-of-type {
      .divider {
        display: none !important;
      }
    }
  }
}

.properties-wrapper {
  div {
    .property.card-body {
      &:last-child {
        .property-divider {
          display: none;
        }
      }
    }
  }
}
