header {
	display: flex;
    flex-direction: column;

	.how {
		font-size: 11px;
		font-weight: 500;
		text-decoration: underline;
		color: $tc-accent;
		display: flex;
		justify-content: flex-end;
		padding-top:20px;
		padding-right: 20px;
		cursor: pointer;
	}
}

nav {
	height: 100px;
	display: flex;
	align-items: center;

	ul {
		list-style: none;
		padding-left: 0;

		li {
			display: inline-block;
			margin: 0 20px;

			&.pinboard-btn{
				padding: 0 20px;
				background-color: $tc-accent;

				&:hover{
					background-color: #9A6B3B;
					transition: background-color 0.3s;
				}
			}


			a {
				display: block;
				color: $tc-dark;
				font-weight: bold;
				font-size: 13px;
				padding-top: 10px;
				padding-bottom: 10px;
				position: relative;
				cursor: pointer;

				&:after {
					position: absolute;
					left: 50%;
					bottom: 0px;
					transform: translateX(-50%);
					content: '';
					display: block;
					width: 0px;
					height: 2px;
					background-color: $tc-dark;
					margin: 0 auto;
					transition: width 0.3s;
				}

				&:hover:not(.pinboard){
					&:after {
						width: 100%;
					}
				}



				&.pinboard {
					color:$tc-white;
					padding-left: 15px;
					background: url(./../images/icon-pinboard.png) no-repeat;
					background-size: 8px 16px;
					background-position: center left;	

					
				}
			}
			a.is-active:after{ width: 100%; }
		}
	}
}
