.form-block {
  width: 580px;
  margin: 0 auto;
}

.field-wrapper {
  margin-bottom: 10px;

  label.field-label {
    min-width: 180px;
    text-align: left;
    padding-right: 10px;
    font-size: 12px;
    color: $tc-dark;
  }

  label.home {
    min-width: 240px;
  }

  .field {
    position: relative;
    display: inline-block;
    width: 340px;
  }

  input[type='text'],
  input[type='email'],
  input[type='password'],
  select {
    width: 100%;
    height: 40px;
    appearance: none;
    border: 1px solid $element-border-color;
    background-color: #fff;
    border-radius: 4px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #fff;

    &:focus {
      outline: none;
      outline-width: 0px;
    }

    &.multi-select {
      option {
        display: none;
      }
    }

    &.multi-select-active {
      border-bottom: 1px solid #fff;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      background-image: url(./../images/icon-up.png);

      option {
        display: none;
      }
    }

    &:disabled {
      border: none;
    }
  }

  .fake-select {
    @extend select;
  }

  select {
    padding-right: 50px;
    background: url(./../images/icon-down.png) #fff no-repeat;
    background-position: top 50% right 20px;
  }

  input[type='checkbox'] {
    & + label {
      margin-left: 10px;
      font-size: 13px;
      font-weight: 400;
      max-width: 90%;
      vertical-align: top;
    }
  }

  textarea {
    border: 1px solid $element-border-color;
    border-radius: 4px;
    padding: 10px;
    width: 100%;
    min-height: 120px;
  }

  .sub-fields {
    label {
      color: $tc-dark;
      min-width: initial;
      font-weight: 600;
      padding: 0 15px;
      font-size: 12px;
      margin-bottom: 0;

      &:first-of-type {
        padding-left: 0;
      }
    }

    &.hlp-flex {
      label {
        flex: 0 0 45px;
      }
    }
  }

  .multi-select-tray {
    display: none;
    position: absolute;
    z-index: 9;
    width: 100%;
    height: auto;
    padding: 15px;
    border: 1px solid $element-border-color;
    border-top: none;
    border-radius: 4px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    background-color: #fff;
    overflow: hidden;
    max-height: 150px;
    overflow-y: auto;

    &.active {
      display: block;
    }
  }

  .multi-select-display {
    position: absolute;
    top: 0px;
    width: 100%;
    height: auto;
    padding: 10px 50px 10px 20px;
    pointer-events: none;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .multi-select-autocomplete-input {
    position: absolute !important;
    left: 0px !important;
    width: 85% !important;
    zindex: 99 !important;
    border: none !important;
    background-color: transparent !important;
  }

  &.label-on-top {
    .field-label,
    .field {
      min-width: initial;
      width: 100%;
    }

    .field-label {
      text-align: left;
    }
  }

  .help-block {
    width: 340px;
    margin-left: auto;
    color: #c00;
  }

  .tags-multi-select {
    @extend select;

    display: inline-block;
    vertical-align: middle;
    min-height: 40px;
    height: auto;
    padding: 5px 5px 0;

    .tag {
      margin: 0 5px 5px 0;
    }

    .search-tags {
      padding-right: 35px;
    }
  }

  // FOR IE11
  select::-ms-expand {
    display: none;
  }

  .form-element {
    width: 100%;
  }
}

.search-form-wrapper {
  .form-block {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding: 0;
    max-width: 710px;

    .field-wrapper {
      display: flex;
      align-items: center;

      .field {
        width: auto;
        flex-grow: 1;
      }
    }

    @media only screen and (min-width: 1400px) {
      // width: 1050px;
    }
  }
}

.sidebar {
  .field-wrapper {
    input[type='text'],
    input[type='email'],
    input[type='password'],
    select {
      padding-left: 10px;
      padding-right: 10px;
    }

    select {
      padding-right: 20px;
      background-position: top 50% right 5px;
    }
  }

  .sub-fields {
    label {
      padding: 0 5px;
      font-style: normal;
    }

    &.hlp-flex {
      label {
        flex: 0 0 35px;

        &:last-of-type {
          text-align: right;
        }
      }
    }
  }
}
