body.admin {
  background: $page-bg;

  header {
    height: 80px;
    background-color: #fff;
    margin-bottom: 40px;

    .idea-logo {
      // width: 100px;
      width: 70px;
      height: 100px;
      background: url(./../images/idea-logo-30years.svg) no-repeat;
      background-size: contain;
      position: absolute;
      top: 10px;
      left: 20px;
    }
  }

  nav {
    height: 80px;
    margin-left: 140px;

    ul {
      margin: 0;

      li {
        margin: 0;
        padding: 0;
      }
    }

    a {
      height: 80px;
      padding: 30px 20px 0;
    }
  }

  .admin-page-wrapper {
    background-color: #fff;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .login-wrapper {
    .form-block {
      width: auto;

      .field-label {
        min-width: 80px;
      }
    }
  }

  .admin-page-title {
    font-size: 26px;
    font-weight: 700;
    color: $tc-dark;
    margin-right: 15px;
  }

  .admin-page-header {
    .form-block {
      width: auto;

      .field-wrapper {
        margin-bottom: 0;
      }

      .field-label {
        min-width: auto;
      }

      .field {
        width: 240px;
      }
    }
  }
}
