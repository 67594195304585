// Fonts
// @import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,700');
// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap-sass/assets/stylesheets/bootstrap';

@import 'mixin';
@import 'global';
@import 'sidebar';
@import 'header';
@import 'form';
@import 'button';

@import 'page-home';
@import 'page-search';
@import 'page-process';
@import 'page-about';

@import 'admin-general';
@import 'admin-processes';
@import 'admin-filter';
@import 'overlay';

@import 'mobile';

@import 'helpers';
// @import "pdf";
