$raleway: 'raleway';

@page layout_3 {
  odd-header-name: header3;
  odd-footer-name: footer;
  margin-top: 120px;
}

@page noHeader {
  odd-header-name: _blank;
}


div.layout_3 {
  page-break-before: always;
  page: layout_3;
  // border: 1px solid pink;
  // padding-top: 10px;
}

div.cover {
  page-break-before: always;
  page: noHeader;
  padding-top: -75px;
}

hr {
  background: rgba(#2d495f, 0.1);
  // color: rgba(#2d495f, 0.5);
  margin-bottom: 30px;
  border: 0;
  height: 1px;
}

.pdf-cover-header {
  background: #EBF0F4;
  height: 200px;
}



.pdf-cover-logos {
  margin-top: -30px;
  margin-bottom: 160px;
  .logo-cnet {
    padding-left: 30px;
    // border: 1px solid;
  }
}


.pdf-title {
  font-family: $raleway, 'sans-serif';
  // font-family: 'sans-serif';
  // font-weight: bold;
  line-height: 1;
  color: #2d495f;
}


.pdf-cover-title {
  font-size: 42pt;
}

.content-wrapper {
  margin-left: 60px;
  margin-right: 60px;
}

.pdf-toc-title {
  font-size: 18pt;
}

.pdf-toc-item {
  margin-top: 30px;
  margin-bottom: 20px;
}
.pdf-toc-process_name {
  font-size: 14pt;
}

.pdf-toc-stage_number, .pdf-toc-stage_name, .pdf-toc-question {
  font-size: 10pt;
  line-height: 14pt;
}

.pdf-toc-stage_number {
  color: #a9bdc8;
}

//processes layout
#process-header-layout {
  padding-top: -35px;
  // margin-bottom: 100px;
  // border: 1px solid #f00;
}

.pdf-process-logos {
  margin-top: -15px;
  margin-left: 60px;
  .logo-cnet {
    padding-left: 10px;
    // border: 1px solid;
  }
  .process-name {
    padding-left: 200px;
  }
}

.pdf-process-header {
  background: #EBF0F4;
  height: 30px;
  // margin-top: -20px;
}

.pdf-process-title {
  font-size: 10pt;
}

.pdf-process-item {
  margin-bottom: 20px;
}

.pdf-category-number {
  color: #a9bdc8;
  margin-bottom: 5px;
}

.pdf-category-description{
  margin-bottom: 20px;
}

.pdf-property {
  font-family: 'opensans';
  font-size: 8pt;
  line-height: 14pt;
  color: #2a2d2f;
  font-weight: 600;
  margin-bottom: 20px;
}

.pdf-property-question {
  // font-weight: bold;
  font-family: 'opensansbold';
}

.pdf-property-answer {
  margin-bottom: 20px;
  font-family: 'opensans';
  .title {
    // font-weight: bold;
    font-family: 'opensansbold';
  }
}

#property-header-layout {
  padding-top: -35px;
}

.pdf-property-header {
  background: #EBF0F4;
  height: 40px;
}

.pdf-property-logos {
  margin-top: -20px;
  margin-left: 60px;
  .logo-cnet {
    padding-left: 15px;
    // border: 1px solid;
  }
}

.pdf-process-title-layout3 {
  font-size: 16pt;
  line-height: 24pt;
}

.content-wrapper-property {
  margin: 60px;
  margin-top: 150px;
}


.layout-3-heading {
  font-size: 16pt;
  line-height: 22pt;
}

#footer {
  // padding-bottom: -100px;
  // border: 1px solid yellow;
  padding-bottom: -34px;
}

.footer {
  // height: 100px;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 15px;
  padding-bottom: 15px;
  background: #EBF0F4;

  // border: 1px solid pink;
  //
}

.credits {
  color: #a9bdc8;
  width: 350px;
  font-family: $raleway;
  font-size: 9pt;
}
.page_number {
  // float: right;
  width: 350px;
  display: inline-block;
  text-align: right;
  font-family: $raleway;
  font-size: 9pt;
}


.large-question-text {
  font-size: 10pt;
  line-height: 14pt;
  color: #2d495f;
}
