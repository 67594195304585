.overlay-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(44, 73, 95, 0.9);
  padding: 50px 0;
}

.overlay-box {
  width: 610px;
  padding: 40px 20px;
  background-color: #fff;
  margin: 0 auto;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  // overflow: auto;

  .overlay-scroll {
    overflow-y: auto;
  }

  .title {
    color: $tc-dark;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    margin-bottom: 30px;
  }

  .title-error {
    color: $tc-dark;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
  }

  .text {
    text-align: center;
    margin-bottom: 30px;
  }

  .body {
    margin-bottom: 20px;
    flex: 1;
    overflow-y: auto;

    &.ofv {
      overflow: visible;
    }

    @media all and (-ms-high-contrast: none) {
      min-height: 250px;
    }
  }

  .actions {
    flex: 0 0 50px;
    text-align: center;

    .btn {
      margin: 0 5px;
    }
  }

  .overlay-close {
    display: flex;
    width: 24px;
    height: 24px;
    background: url(./../images/icon-close.png) center center no-repeat;
    background-size: contain;
    border-radius: 50%;
    position: absolute;
    top: -32px;
    right: -32px;
    cursor: pointer;
  }
}

.overlay-box-process {
  width: 610px;
  padding: 40px 20px;
  background-color: #fff;
  margin: 0 auto;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;

  .overlay-scroll {
    overflow-y: auto;
  }

  .title {
    color: $tc-dark;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    margin-bottom: 30px;
  }

  .title-error {
    color: $tc-dark;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
  }

  .text {
    text-align: center;
    margin-bottom: 30px;
  }

  .body {
    margin-bottom: 20px;
    flex: 1;
    overflow-y: auto;

    &.ofv {
      overflow: visible;
    }

    @media all and (-ms-high-contrast: none) {
      min-height: 250px;
    }
  }

  .actions {
    flex: 0 0 50px;
    text-align: center;

    .btn {
      margin: 0 5px;
    }
  }

  .overlay-close {
    display: flex;
    width: 24px;
    height: 24px;
    background: url(./../images/icon-close.png) center center no-repeat;
    background-size: contain;
    border-radius: 50%;
    position: absolute;
    top: -32px;
    right: -32px;
    cursor: pointer;
  }
}

.overlay-pinboard {
  .process {
    margin-bottom: 10px;
  }

  .process-data {
    color: $tc-dark;
    font-weight: 600;
    width: 60%;

    .process-title,
    .stage,
    .property {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .pinboard-empty {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 25px;
  }
}

.overlay-wrapper {
  .form-block {
    max-width: 340px;
    margin: 0 auto;
  }

  .form-block-error {
    max-width: 550px;
    margin: 0 auto;
  }

  .btn {
    min-width: 100px;
  }

  .add-more-answer,
  .remove-answer {
    display: inline-block;
    text-transform: uppercase;
    font-weight: 700;
    color: $tc-accent;
  }

  .remove-answer {
    position: absolute;
    bottom: 40px;
    right: -70px;
  }
}

.table-error {
  padding: 20px;
  width: 100%;
  border: 1px solid #d5d9dc;
  th {
    padding: 20px;
    border: none;
  }
  td {
    padding: 20px;
    border: none;
  }
  thead {
    border-bottom: 1px solid #d5d9dc;
  }
  tr {
    &:nth-child(even) {
      background-color: #f8fafb;
    }
  }
  .text-error {
    color: #a94141;
  }
}
